import { IFilterShipmentResponse, IShipment, ShipmentPriority, IQueryHelperResponse } from "../models/ShipmentsView";
import { HumsaferError } from "../models/HumsaferError";
import { BACKEND_URL_V2, get, post } from "../utils/Api";

interface IGetFilterShipments {
  shipments: IShipment[];
  lastVisibleShipmentId: string;
  firstVisibleShipmentId: string;
  filteredRecords: number;
  totalRecords: number;
  currentPage: number;
  totalPages: number;
}

interface ICreateShipmentShareLink {
  shareLink: string;
}

interface ICreateShipmentShareLink {
  shipmentId: string,
  priority: ShipmentPriority
}

interface Plant {
  loadPlantCode: string,
  loadPlantName: string,
}

interface IQueryHelperObject {
  companyId: string,
  "Transporter_Names": string[],
  from: string[],
  to: string[],
  loadPlantCodes: string[],
  plantDetails: Plant[],
}

interface IQueryHelper {
  queryHelperList: IQueryHelperObject[];
}

interface ICountObject {
  appSetUpIncompleteShipmentsCount: number,
  unTrackedShipmentsCount: number,
  inTransitShipmentsCount: number,
  prioritizedShipmentsCount: number,
  totalShipmentsCount: number,
  completedShipmentsCount: number,
}

interface IStatusCount {
  shipmentsStatusWiseCount: ICountObject;
}

function objectToQueryParams(obj) {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value !== null && value !== undefined ? value.toString() : 'null')}`)
    .join('&');
}

export const getShipments = async (companyId: string, data: any): Promise<IFilterShipmentResponse | HumsaferError> => {
  try {
    const queryParams = objectToQueryParams(data);
    const response = await get<IGetFilterShipments>(`${BACKEND_URL_V2}/companies/${companyId}/shipmentsWithPagination?${queryParams}`);
    if (response.parsedBody?.shipments) {
      return response.parsedBody;
    }
    const errorMessage = response.serverError?.error.message ?? "Shipment could not be retrieved";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};

export const getFilteredShipments = async (companyId: string, data: any): Promise<IFilterShipmentResponse | HumsaferError> => {
  try {
    const queryParams = objectToQueryParams(data);
    const response = await get<IGetFilterShipments>(`${BACKEND_URL_V2}/companies/${companyId}/filterWithLocations?${queryParams}`);
    if (response.parsedBody?.shipments) {
      return response.parsedBody;
    }
    const errorMessage = response.serverError?.error.message ?? "Shipment could not be retrieved";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};

export const getStatusFilteredShipments = async (companyId: string, data: any): Promise<IFilterShipmentResponse | HumsaferError> => {
  try {
    const queryParams = objectToQueryParams(data);
    const response = await get<IGetFilterShipments>(`${BACKEND_URL_V2}/companies/${companyId}/getStatusWiseShipments?${queryParams}`);
    if (response.parsedBody?.shipments) {
      return response.parsedBody;
    }
    const errorMessage = response.serverError?.error.message ?? "Shipment could not be retrieved";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};

export const getQueryHelper = async (companyId: string): Promise< IQueryHelperResponse | HumsaferError> => {
  try {
    const response = await get<IQueryHelper>(`${BACKEND_URL_V2}/companies/${companyId}/getAllQueryHelperDetails`);
    if (response.parsedBody) {
      return response.parsedBody;
    }
    const errorMessage = response.serverError?.error.message ?? "Data could not be retrieved";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};

export const getAllStatsCount = async (companyId: string, data: string): Promise< ICountObject | HumsaferError> => {
  try {
    const response = await get<IStatusCount>(`${BACKEND_URL_V2}/companies/${companyId}/getAllCount?shipmentsStartingFrom=${data}`);
    if (response.parsedBody?.shipmentsStatusWiseCount) {
      return response.parsedBody.shipmentsStatusWiseCount;
    }
    const errorMessage = response.serverError?.error.message ?? "Data could not be retrieved";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};


export const createShipmentShareLink = async (companyId: string, shipmentId: string): Promise<ICreateShipmentShareLink | HumsaferError> => {
  try {
    const response = await post<ICreateShipmentShareLink>(`${BACKEND_URL_V2}/companies/${companyId}/shipments/${shipmentId}/shareLink`);
    if (response.parsedBody) {
      return response.parsedBody;
    }
    const errorMessage = response.serverError?.error.message ?? "Something went wrong";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};

export const changeShipmentPriority = async (companyId: string, shipmentId: string, priority: ShipmentPriority): Promise<ICreateShipmentShareLink | HumsaferError> => {
  try {

    const response = await post<ICreateShipmentShareLink>(`${BACKEND_URL_V2}/companies/${companyId}/shipments/${shipmentId}/priority/${priority}`);
    if (response.parsedBody) {
      return response.parsedBody;
    }
    const errorMessage = response.serverError?.error.message ?? "Something went wrong";
    return new HumsaferError(errorMessage);
  } catch (err) {
    return new HumsaferError("Something went wrong");
  }
};

