import { Button, CardContent, Grid, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import { useContext, useState } from "react";
import styles from "./ShipmentListReport.module.css";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { exportShipmentsReport } from "../../api/Reports";
import { Loading } from "../loading/Loading";
import { HumsaferError } from "../../models/HumsaferError";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment, { Moment } from "moment";
import { Auth } from "../../utils/Auth";
import { CompanyDataContext } from "../../contexts/CompanyDataContext";

const ShipmentReport: React.FC = (props) => {
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
    const [endDate, setEndDate] = useState(moment().endOf('month'));
    const [isLoading, setIsLoading] = useState(false);

    const { selectedCompany } = useContext(CompanyDataContext);

    const onShipmentListReportRequest = async () => {

        let companyId = Auth.getInstance().getCompanySettings()?.id || "";
        let email = Auth.getInstance().getUserSettings()?.email || "";
        if (selectedCompany) {
            companyId = selectedCompany.id;
        }

        setIsLoading(true);
        const response = await exportShipmentsReport(
            companyId,
            `${startDate.valueOf()}`,
            `${endDate.valueOf()}`,
            email,
        )

        setIsLoading(false);
        if (response instanceof HumsaferError) {
            toast.error(response.getErrorMessage())
        } else {
            toast.success("Report sent to registered email")
        }
    }

    const handlefromDateChange = (date: Moment | null) => {
        if (date) {
            setStartDate(date);

            // Calculate the maximum allowed to date based on the selected from date
            const maxAllowedEndDate = moment(date).add(90, 'days');

            // If the current to date is outside the 90-day range, set it to the max allowed date
            if (moment(endDate).isAfter(maxAllowedEndDate)) {
                setEndDate(maxAllowedEndDate);
            }
        }
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Card className={styles.paymentInfo_container}>
                <CardContent>
                    <Grid container spacing={3} className={styles.paymentInfo_row}>
                        <>
                            <Grid item md={3}>
                                <div className={styles.paymentInfo_label}>Start Date</div>
                                <div className={styles.paymentInfo_label}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            renderInput={props =>
                                                <TextField {...props} />}
                                            value={startDate}
                                            onChange={handlefromDateChange}
                                            maxDate={endDate}
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                            <Grid item md={3}>
                                <div className={styles.paymentInfo_label}>End Date</div>
                                <div className={styles.paymentInfo_label}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            renderInput={props => <TextField {...props} />}
                                            value={endDate}
                                            onChange={(date) => {
                                                if (date) {
                                                    setEndDate(date)
                                                }
                                            }}
                                            minDate={startDate}
                                            maxDate={moment(startDate).add(90, 'days')} // Limit the to date selection
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Grid>
                        </>
                        <Grid item md={6}>
                        </Grid>
                    </Grid>


                    <Grid container spacing={2} className={styles.paymentInfo_row}>
                        <Grid item md={3}>
                            <div className={styles.paymentInfo_label}>
                                <Button
                                    className={styles.submitButton}
                                    variant="contained"
                                    onClick={() => onShipmentListReportRequest()}>
                                    <Loading text="Request Report" isLoading={isLoading} />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>

            </Card>

        </>
    );
}

export default ShipmentReport;
