import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TablePagination, styled, tableCellClasses, tableRowClasses, Paper, Grid, TableSortLabel, Select, MenuItem, FormControl } from "@mui/material";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { createArrayWithNumbers } from "../../utils/Utils";
import { Loading } from "../loading/Loading";

interface ITableHeaderProps<T> {
    header: string;
    sortByField?: string;
    filter?: IFilterProps<T>;
}

interface IFilterProps<T> {
    filterValues: string[];
    isValid: (val: T, selectedFilter: string) => boolean;
}

export interface IGenericTableProps<T> {
    headers: ITableHeaderProps<T>[] | string[];
    data: T[];
    defaultSortByField?: string;
    dataRenderer: (dataRow: T, column: number, row: number) => string | number | ReactNode;
    getWidth?: (column: number) => number | undefined;
    onRowClick?: (dataRow: T) => void;
    pagination?: boolean;
    maxHeight?: string;
    hover?: boolean;
    cursor?: "pointer";
    hoverIndex?: number;
    setHoverIndex?: (index?: number) => void;
    isLoading?: boolean;
    alignContent?: "left" | "center";
    filteredRecords?: number,
    onPageChange?: (newPage: number) => void,
    onRowsPerPageChange?: (rowsPerPage: number) => void;
    currentPage?: number
    totalPages?: number
    pageSize?: number
}

interface ISortState {
    selectedFieldToSort: string;
    direction: "asc" | "desc";
}

// In this, the type of T will be inferred from the type of `data` property.
function GenericTable<T>(props: IGenericTableProps<T>) {
    const {
        headers,
        dataRenderer,
        onRowClick = (_: T) => { },
        pagination = false,
        maxHeight = "100%",
        getWidth,
        hover = true,
        cursor,
        hoverIndex,
        setHoverIndex,
        isLoading = false,
        data,
        defaultSortByField,
        alignContent = "left",
        filteredRecords,
        onPageChange,
        onRowsPerPageChange,
        currentPage,
        totalPages,
        pageSize
    } = props;
    // console.log({currentPage, totalPages})
    const [state, setState] = useState<T[]>(data);
    const [selectedFilters, setSelectedFilters] = useState<Map<number, string>>(new Map());

    let normalizedHeaders = useMemo(() => {
        if (headers.length > 0) {
            return headers.map((value: string | ITableHeaderProps<T>) => {
                let tableHeader: ITableHeaderProps<T>;
                if (typeof value === "string") {
                    tableHeader = { header: value }
                } else {
                    tableHeader = value;
                }

                return tableHeader;
            });
        } else {
            return [];
        }
    }, [headers]);

    const [sortState, setSortState] = useState<ISortState>({
        selectedFieldToSort: "",
        direction: "asc"
    });

    const addFilter = (key: number, filterValue: string) => {
        const newState = new Map(selectedFilters);
        newState.set(key, filterValue);
        setSelectedFilters(newState);
    }

    function getNestedValue(obj: any, path: string): any {
        return path.split('.').reduce((acc, key) => acc?.[key], obj);
    }
    
    const sortByField = useCallback((arr: T[], key: string, ascending: boolean): T[] => {
        const newData = [...arr];
        newData.sort((a: any, b: any) => {
            const nestedValueA = getNestedValue(a, key);
            const nestedValueB = getNestedValue(b, key);

            if (nestedValueA === undefined && nestedValueB === undefined) {
                return 0;
            }
            if (nestedValueA === undefined || nestedValueB === undefined) {
                return ascending ? (nestedValueA === undefined ? 1 : -1) : (nestedValueB === undefined ? -1 : 1);
            }

            if (nestedValueA > nestedValueB) {
                return ascending ? 1 : -1;
            } else if (nestedValueA < nestedValueB) {
                return ascending ? -1 : 1;
            }
            return 0;
        });
        return newData;
    }, [])


    const filterData = useCallback((tableHeaders: ITableHeaderProps<T>[], dataList: T[]): T[] => {
        let setList: Set<T>[] = []
        tableHeaders.forEach((header, index) => {
            if (header.filter === undefined) {
                setList.push(new Set([...dataList]));
                return;
            }
            let collector: Set<T> = new Set();
            dataList.forEach(e => {
                const isValid = header.filter?.isValid;

                if (header.filter && (selectedFilters.get(index) === "All" || selectedFilters.get(index) === undefined ||
                    (isValid !== undefined && isValid(e, selectedFilters.get(index) ?? "")))) {
                    collector.add(e);
                }
            });
            setList.push(collector)
        });

        const nonEmptySetsList = setList.filter((e, index) => {
            if (tableHeaders[index].filter !== undefined || e.size !== 0) {
                return true;
            }
            return false;
        });
        const commonObjects = nonEmptySetsList.length > 0 ?
            nonEmptySetsList.reduce((accumulator, currentSet) => {
                return new Set(Array.from(accumulator).filter(obj => currentSet.has(obj)));
            }) : new Set<T>();

        return Array.from(commonObjects);
    }, [selectedFilters]);

    useEffect(() => {
        let newData: T[] = [];
        if (data && data.length > 0) {
            newData = [...data];

            newData = filterData(normalizedHeaders, [...newData])
            if (defaultSortByField) {
                setSortState({
                    selectedFieldToSort: defaultSortByField,
                    direction: "asc"
                })
                newData = sortByField(newData, defaultSortByField, true);
            }
        }
        setState(newData);
        if (!pagination) {
            setRowsPerPage(newData.length);
        }
    }, [data, selectedFilters, sortByField, defaultSortByField, filterData, pagination, normalizedHeaders]);

    const sort = useCallback((key: string) => {
        let newSortState: ISortState = {
            selectedFieldToSort: "",
            direction: "asc"
        };
        if (sortState.selectedFieldToSort === key) {
            const prevSortDirection = sortState.direction;
            newSortState = {
                ...sortState,
                direction: prevSortDirection === "asc" ? "desc" : "asc"
            };
        } else {
            newSortState = {
                selectedFieldToSort: key,
                direction: "asc"
            };
        }
        setSortState(newSortState);

        const ascending = newSortState.direction === "asc";

        const newData = sortByField([...state], key, ascending);

        setState(newData);
    }, [sortState, state, sortByField]);

    const numberOfRows = state.length;

    const GenericTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            color: "black",
            padding: "10 20",
            fontSize: 14,
            fontWeight: "bold"
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14
        },
        [`&:first-of-type`]: {
            textAlign: "left"
        }
    }));

    const GenericTableRow = styled(TableRow)(() => ({
        [`&.${tableRowClasses.root}`]: {
            cursor: cursor
        }
    }));

    const [page, setPage] = useState(currentPage ?? 0);
    const [rowsPerPage, setRowsPerPage] = useState(pagination ? pageSize : numberOfRows);

    const handleChangePage = (_: any, newPage: number) => {
        onPageChange?.(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        onRowsPerPageChange?.(parseInt(event.target.value));
    };
    return (
        <TableContainer component={Paper} sx={{ maxHeight: maxHeight ?? "" }}>
            <Table size='small' sx={{ maxWidth: "100%" }}>
                <TableHead>
                    <GenericTableRow>
                        {normalizedHeaders.map((header, headerIndex) =>
                            <GenericTableCell
                                width={getWidth ? getWidth(headerIndex) : ""}
                                key={headerIndex}
                                align={alignContent}
                                sx={{
                                    verticalAlign: "top"
                                }}
                            >
                                <Grid flexDirection={"column"} alignItems={"flex-start"} >
                                    <Grid>
                                        {header.sortByField === undefined && (
                                            <>
                                                {header.header}
                                            </>
                                        )}
                                        {header.sortByField && (
                                            <TableSortLabel
                                                active={sortState.selectedFieldToSort === header.sortByField}
                                                direction={sortState.direction}
                                                onClick={() => sort(header.sortByField!)}
                                            >
                                                {header.header}
                                            </TableSortLabel>
                                        )}
                                    </Grid>

                                    {header.filter && (
                                        <FormControl sx={{ m: 1 }} size="small" >
                                            <Select
                                                defaultValue={"All"}
                                                value={selectedFilters.get(headerIndex)}
                                                onChange={(e) => {
                                                    const filterValue = e.target.value;
                                                    addFilter(headerIndex, filterValue)
                                                }}
                                                name={headerIndex.toString()}
                                                variant="standard"
                                                sx={{ textAlign: "center" }}
                                            >
                                                <MenuItem value="All">All</MenuItem>
                                                {header.filter.filterValues.map(value => <MenuItem value={value} key={value}>{value}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                            </GenericTableCell>
                        )}
                    </GenericTableRow>
                </TableHead>
                <TableBody>
                    {isLoading && (
                        <GenericTableRow>
                            <GenericTableCell
                                colSpan={normalizedHeaders.length}
                                sx={{ textAlign: "center !important" }}
                            >
                                <Loading isLoading={true} />
                            </GenericTableCell>
                        </GenericTableRow>
                    )}
                    {!isLoading && (
                        <>
                            {numberOfRows === 0 && (
                                <GenericTableRow>
                                    {normalizedHeaders.map((_, index) =>
                                        <GenericTableCell
                                            key={index}
                                            width={getWidth ? getWidth(index) : ""}
                                        >
                                            -
                                        </GenericTableCell>)}
                                </GenericTableRow>
                            )}

                            {numberOfRows > 0 && dataRenderer && (
                                <>
                                    {createArrayWithNumbers(numberOfRows)
                                        .slice(0,rowsPerPage)
                                        .map((rowIndex) => (
                                            <GenericTableRow
                                                key={rowIndex}
                                                hover={hover}
                                                onMouseOver={() => {
                                                    if (setHoverIndex) {
                                                        setHoverIndex(rowIndex);
                                                    }
                                                }}
                                                onMouseOut={() => {
                                                    if (setHoverIndex) {
                                                        setHoverIndex(undefined);
                                                    }
                                                }}
                                                sx={{
                                                    backgroundColor: rowIndex === hoverIndex ? "#0000000a" : undefined
                                                }}
                                                onClick={() => {
                                                    onRowClick(state[rowIndex]);
                                                }}
                                            >

                                                {normalizedHeaders.map((_, columnIndex) => {
                                                    const value = dataRenderer(state[rowIndex], columnIndex, rowIndex) ?? "";
                                                    return (
                                                        <GenericTableCell
                                                            key={`${rowIndex}_${columnIndex}`}
                                                            width={getWidth ? getWidth(columnIndex) : ""}
                                                            align={alignContent}
                                                        >
                                                            {(typeof value === "number" ? (value.toFixed(2)) : value)}
                                                        </GenericTableCell>
                                                    );
                                                })}
                                            </GenericTableRow>
                                        ))
                                    }
                                </>
                            )}
                        </>
                    )}
                </TableBody>
            </Table>
            {pagination && (
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100, 150, 500]}
                    component="div"
                    count={filteredRecords ?? data.length} // Total number of records
                    rowsPerPage={rowsPerPage ?? 20}
                    page={page} // Current page (0-indexed)
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Shipments per page"
                    nextIconButtonProps={{
                        disabled: page === totalPages,
                    }}
                    backIconButtonProps={{
                        disabled: page === 1,
                    }}
                    labelDisplayedRows={({ from, to, count, page }) => {
                        return `Page ${page} of ${Math.ceil(count / (rowsPerPage ?? 20))}`
                    }}
                />
            )}
        </TableContainer>
    );
}

export default GenericTable;
