import { Box, Button, Card, CardContent, CircularProgress, Grid, IconButton, MenuItem, TextField, Tooltip } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getShipments, getFilteredShipments, createShipmentShareLink, changeShipmentPriority, getQueryHelper, getAllStatsCount, getStatusFilteredShipments } from '../../api/ShipmentsListApi';
import Toast, { IToastBasicProps } from '../../components/Toast/Toast';
import { HumsaferThemeContext } from '../../contexts/HumsaferThemeContext';
import { HumsaferError } from '../../models/HumsaferError';
import { IShipment, IDateRange, IFilterStatus, AppHealth, ShipmentTrackingStatus, ShipmentStatus, ShipmentPriority, IFilterShipmentResponse, IQueryHelperResponse, ICountObject, ISearchType, Plant } from '../../models/ShipmentsView';
import styles from "./ShipmentsList.module.css";
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Loading } from '../../components/loading/Loading';
import { useMediaQuery, Theme } from '@mui/material';
import { formatDateForBackend, formatTimestamp } from '../../utils/DateUtils';
import ReportIcon from '@mui/icons-material/Report';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import GenericTable from '../../components/genericTable/GenericTable';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import ProgressBar from '../../components/progressBar/ProgressBar';
import ActionMenu from "./ShipmentsActionMenu"
import CompanyInfoWithSwitcher from '../../components/companyInfoWithSwitcher/CompanyInfoWithSwitcher';
import { isAllowedToSwitchCompanies } from '../../utils/CapabitilityUtils';
import { CompanyDataContext } from '../../contexts/CompanyDataContext';
import { Auth } from '../../utils/Auth';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import MobileOffIcon from '@mui/icons-material/MobileOff';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { getDisplayDateRange } from '../../utils/DisplayUtils';
import CSVDownload from '../../components/utils/CSVDownload';
import { getShipmentsListDataForExport } from '../../utils/CSVExportUtils';
import moment from 'moment';
import HumsaferDialog from '../../components/humsaferDialog/HumsaferDialog';

const initialSearchObject = {
    shipmentId: null,
    vehicleNumber: null,
    driverName: null,
    driverMobileNumber: null,
}

const initiaFilterObject = {
    fromCity: null,
    toCity: null,
    transporter: null,
    loadPlantCode: null
}

const filterTypeOptions = {
    shipmentId: "Shipment Id",
    vehicleNumber: "Vehicle Number",
    driverName: "Driver Name",
    driverMobileNumber: "Driver Mobile Number (without +91)",
}

const TATA_COMPANY_IDS = ["K3R8gs01kggEcS0gvXT8", "yGD2kAp8lX5My9MCtE4u"];

const ShipmentList: React.FC = React.memo(() => {
    const [shipments, setShipments] = useState<IShipment[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [dateRange, setDateRange] = useState<IDateRange>(() => window.localStorage.getItem("dateRange") as IDateRange || IDateRange.LAST_WEEK);
    const [inTransitCount, setInTransitCount] = useState(0)
    const [appStatusUnhealthyCount, setAppStatusUnhealthyCount] = useState(0)
    const [priorityCount, setPriorityCount] = useState(0)
    const [completeCount, setCompleteCount] = useState(0)
    const [totalCount, setTotalCount] = useState(0)
    const [filterStatus, setFilterStatus] = useState<IFilterStatus>(() => window.localStorage.getItem("filterStatus") as IFilterStatus || IFilterStatus.ALL);
    const [filteredAllShipments, setFilteredAllShipments] = useState<IShipment[]>([]);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [showToast, setShowToast] = useState<IToastBasicProps>({
        open: false,
        message: "",
        type: "success",
    });
    const [isFilter, setIsFilter] = useState(false);
    const [isSearchFilter, setIsSearchFilter] = useState(false);
    const { primaryColor } = useContext(HumsaferThemeContext);
    const [shipmentStartCity, setShipmentStartCity] = useState<string[]>([]);
    const [shipmentDestinationCity, setShipmentDestinationCity] = useState<string[]>([]);
    const [shipmentTransporter, setShipmentTransporter] = useState<string[]>([]);
    const [allPlantDetails, setAllPlantDetails] = useState<Plant[]>([]);
    const [isPrioritized, setIsPrioritized] = useState<boolean>(false);
    const [isCopyShareLink, setIsCopyShareLink] = useState<boolean>(false);
    const [untrackedShipmentCount, setUntrackedShipmentCount] = useState(0);
    const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);

    // Existing state declarations
    const [filterType, setFilterType] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [lastVisibleShipmentId, setLastVisibleShipmentId] = useState<string | null>(null);
    const [firstVisibleShipmentId, setFirstVisibleShipmentId] = useState<string | null>(null);
    const [pageSize, setPageSize] = useState(100);

    const [totalRecords, setTotalRecords] = useState(0);
    const [filteredRecords, setFilteredRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [fromCity, setFromCity] = useState<string | null>(null);
    const [toCity, setToCity] = useState<string | null>(null);
    const [transporter, setTransporter] = useState<string | null>(null);
    const [loadPlantCode, setLoadPlantCode] = useState<string | null>(null);
    const [isTataCompany, setIsTataCompany] = useState(false);
    const [currentSearch, setCurrentSearch] = useState<string>(ISearchType.SEARCH);

    const handleFilterTypeChange = (event) => {
        const newFilterType = event.target.value;
        setFilterType(newFilterType);
        setFilterValue(''); // Reset the input value
    };

    const handleFilterValueChange = (event) => {
        const value = event.target.value;
        setFilterValue(value);
    };

    const { selectedCompany } = useContext(CompanyDataContext);

    const allowCompanySwitcher = isAllowedToSwitchCompanies();

    const getDateAndCompanyId = useCallback( () => {
        let companyId = Auth.getInstance().getCompanySettings()?.id;
        if (selectedCompany) {
            companyId = selectedCompany.id;
        }

        if(companyId){
            setIsTataCompany(TATA_COMPANY_IDS.includes(companyId));
        }

        let date = formatDateForBackend(new Date());
        switch (dateRange) {
            case IDateRange.TODAY:
                date = formatDateForBackend(new Date());
                break;
            case IDateRange.YESTERDAY:
                date = formatDateForBackend(new Date(new Date().setDate(new Date().getDate() - 1)));
                break;
            case IDateRange.LAST_WEEK:
                date = formatDateForBackend(new Date(new Date().setDate(new Date().getDate() - 7)));
                break;
            case IDateRange.LAST_MONTH:
                date = formatDateForBackend(new Date(new Date().setMonth(new Date().getMonth() - 1)));
                break;
        }
        return {companyId, date}
    }, [dateRange, selectedCompany])


    const fetchQueryData = useCallback(async () => {
        const {companyId, date} = getDateAndCompanyId();

        if(companyId === undefined) {
            return
        }

        const queryResponse = await getQueryHelper(companyId);

        const statsCountResponse = await getAllStatsCount(companyId, date);

        if(statsCountResponse as ICountObject) {
            const total = (statsCountResponse as ICountObject).totalShipmentsCount;
            const inTransit = (statsCountResponse as ICountObject).inTransitShipmentsCount;
            const unHealthy = (statsCountResponse as ICountObject).appSetUpIncompleteShipmentsCount;
            const unTracked = (statsCountResponse as ICountObject).unTrackedShipmentsCount;
            const completed = (statsCountResponse as ICountObject).completedShipmentsCount;
            if(total === (inTransit + unHealthy + unTracked + completed)) {
                setUntrackedShipmentCount((statsCountResponse as ICountObject).unTrackedShipmentsCount);
            }else{
                setUntrackedShipmentCount(total - (inTransit + unHealthy + completed));
            }
            setInTransitCount((statsCountResponse as ICountObject).inTransitShipmentsCount);
            setAppStatusUnhealthyCount((statsCountResponse as ICountObject).appSetUpIncompleteShipmentsCount);
            setPriorityCount((statsCountResponse as ICountObject).prioritizedShipmentsCount);
            setCompleteCount((statsCountResponse as ICountObject).totalShipmentsCount - (statsCountResponse as ICountObject).inTransitShipmentsCount - (statsCountResponse as ICountObject).unTrackedShipmentsCount - (statsCountResponse as ICountObject).appSetUpIncompleteShipmentsCount);
            setTotalCount((statsCountResponse as ICountObject).totalShipmentsCount);
        }

        if((queryResponse as IQueryHelperResponse).queryHelperList?.length) {
            setShipmentStartCity(Array.from(new Set((queryResponse as IQueryHelperResponse).queryHelperList[0].from)));
            setShipmentDestinationCity(Array.from(new Set((queryResponse as IQueryHelperResponse).queryHelperList[0].to)));
            setShipmentTransporter(Array.from(new Set((queryResponse as IQueryHelperResponse).queryHelperList[0]["Transporter_Names"])));
            setAllPlantDetails(Array.from(new Set((queryResponse as IQueryHelperResponse).queryHelperList[0].plantDetails)));
        }

    },[getDateAndCompanyId])

    useEffect(() => {
        fetchQueryData();
    },[fetchQueryData])

    const onSearchFilter = async () => {
        // Check if at least one filter type is selected
        if (!filterType) {
            setShowToast({
                open: true,
                message: `Please select one filter type`,
                type: "error"
            });
            return;
        }

        // Check if filter type is selected and value is provided
        if (filterType && !filterValue) {
            setShowToast({
                open: true,
                message: `Please enter ${filterTypeOptions[filterType]}`,
                type: "error"
            });
            return;
        }
        const newFilterObject = {
            ...initialSearchObject,
            [filterType]: filterType === "driverMobileNumber" ? `91${filterValue}` : filterValue, 
            pageSize: filterType === "driverMobileNumber" ? totalRecords : 20,
        }
        setCurrentSearch(ISearchType.SEARCH);
        setFilterStatus(IFilterStatus.ALL);
        await fetchFilteredShipment(newFilterObject);
    }

    const onSubmitFilter = async () => {
        // Check if at least one field has a value
        if (!fromCity && !toCity && !transporter && !loadPlantCode) {
            setShowToast({
                open: true,
                message: `Please select at least one filter`,
                type: "error"
            });
            return;
        }
        
        const newFilterObject = {
            ...initiaFilterObject,
            fromCity,
            toCity,
            transporter,
            loadPlantCode
        }
        setCurrentSearch(ISearchType.FILTER);
        setFilterStatus(IFilterStatus.ALL);
        await fetchShipment(newFilterObject);
    }

    const handlePageChange = (newPage: number) => {
        console.log({currentSearch, newPage, currentPage})
        if(currentSearch === ISearchType.FILTER) {
            fetchShipment(initiaFilterObject, true, currentPage>newPage)
        }else if (currentSearch === ISearchType.STATUS) {
            applyFilter({}, true, currentPage>newPage)
        }else{
            fetchFilteredShipment( initialSearchObject, true, currentPage>newPage)
        }
    }
    
    const handleRowsChange = (rowsPerPage: number) => {
        setPageSize(rowsPerPage);
    }

    const applyFilter = async (data: object, isPageChange: boolean = false, isBackward: boolean = false) => {
        const {companyId, date} = getDateAndCompanyId();

        if(companyId === undefined) {
            return
        }

        setIsFetching(true);
        let filterObj = {
            ...data,
            shipmentsStartingFrom: date,
            status: filterStatus,
            pageSize: pageSize,
            lastVisibleShipmentId: isPageChange ? lastVisibleShipmentId : null,
            firstVisibleShipmentId: isBackward ? firstVisibleShipmentId : null,
            isBackward
        }
        let shipmentsResponse = await getStatusFilteredShipments(companyId, filterObj);
        if(shipmentsResponse as IFilterShipmentResponse) {
            setTotalRecords((shipmentsResponse as IFilterShipmentResponse).totalRecords || 0);
            setFilteredRecords((shipmentsResponse as IFilterShipmentResponse).filteredRecords || 0);
            setFirstVisibleShipmentId((shipmentsResponse as IFilterShipmentResponse).firstVisibleShipmentId ?? null);
            setLastVisibleShipmentId(( shipmentsResponse as IFilterShipmentResponse).lastVisibleShipmentId ?? null);
            setCurrentPage((shipmentsResponse as IFilterShipmentResponse).currentPage || 1);
            setTotalPages((shipmentsResponse as IFilterShipmentResponse).totalPages || 1);
        }

        const filteredShipments = (shipmentsResponse as IFilterShipmentResponse)?.shipments;
        setIsFetching(false);
        if (filteredShipments instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: filteredShipments.message,
                type: "error"
            });
            return;
        }

        setShipments(filteredShipments);
        setFilteredAllShipments(filteredShipments)
    };

    const fetchFilteredShipment = async (data: object, isPageChange: boolean = false, isBackward: boolean = false) => {
        const {companyId, date} = getDateAndCompanyId();

        if(companyId === undefined) {
            return
        }

        setIsFetching(true);
        let filterObj = {
            ...data,
            shipmentsStartingFrom: date,
            pageSize: filterType=== "driverName" ? totalRecords : pageSize,
            lastVisibleShipmentId: isPageChange ? lastVisibleShipmentId : null,
            firstVisibleShipmentId: isBackward ? firstVisibleShipmentId : null,
            isBackward
        }
        let shipmentsResponse = await getShipments(companyId, filterObj);
        if(shipmentsResponse as IFilterShipmentResponse) {
            setTotalRecords((shipmentsResponse as IFilterShipmentResponse).totalRecords || 0);
            setFilteredRecords((shipmentsResponse as IFilterShipmentResponse).filteredRecords || 0);
            setFirstVisibleShipmentId((shipmentsResponse as IFilterShipmentResponse).firstVisibleShipmentId ?? null);
            setLastVisibleShipmentId(( shipmentsResponse as IFilterShipmentResponse).lastVisibleShipmentId ?? null);
            setCurrentPage((shipmentsResponse as IFilterShipmentResponse).currentPage || 1);
            setTotalPages((shipmentsResponse as IFilterShipmentResponse).totalPages || 1);
        }

        const shipmentOrError = (shipmentsResponse as IFilterShipmentResponse)?.shipments;
        setIsFetching(false);
        if (shipmentOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: shipmentOrError.message,
                type: "error"
            });
            return;
        }

        setShipments(shipmentOrError);
    };

    const fetchShipment = async (data: object, isPageChange: boolean = false, isBackward: boolean = false) => {
        const {companyId, date} = getDateAndCompanyId();

        if(companyId === undefined) {
            return
        }

        setIsFetching(true);
        let filterObj = {
            ...data,
            shipmentsStartingFrom: date,
            pageSize,
            lastVisibleShipmentId: isPageChange ? lastVisibleShipmentId : null,
            firstVisibleShipmentId: isBackward ? firstVisibleShipmentId : null,
            isBackward,
        }
        let shipmentsResponse = await getFilteredShipments(companyId, filterObj);
        if(shipmentsResponse as IFilterShipmentResponse) {
            setTotalRecords((shipmentsResponse as IFilterShipmentResponse).totalRecords || 0);
            setFilteredRecords((shipmentsResponse as IFilterShipmentResponse).filteredRecords || 0);
            setFirstVisibleShipmentId((shipmentsResponse as IFilterShipmentResponse).firstVisibleShipmentId ?? null);
            setLastVisibleShipmentId(( shipmentsResponse as IFilterShipmentResponse).lastVisibleShipmentId ?? null);
            setCurrentPage((shipmentsResponse as IFilterShipmentResponse).currentPage || 1);
            setTotalPages((shipmentsResponse as IFilterShipmentResponse).totalPages || 1);
        }

        const shipmentOrError = (shipmentsResponse as IFilterShipmentResponse)?.shipments;
        setIsFetching(false);
        if (shipmentOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: shipmentOrError.message,
                type: "error"
            });
            return;
        }

        setShipments(shipmentOrError);
    };


    useEffect(() => {
        if(currentSearch === ISearchType.FILTER){
            fetchShipment({
                ...initiaFilterObject,
                fromCity,
                toCity,
                transporter,
                loadPlantCode
            })
        }else if (currentSearch === ISearchType.STATUS){
            applyFilter({});
        }else{
            fetchFilteredShipment(initialSearchObject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dateRange, selectedCompany, pageSize]);

    useEffect(() => {
        if(currentSearch === ISearchType.SEARCH || currentSearch === ISearchType.FILTER) return
        applyFilter({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterStatus])

    const handleFilterStatusChange = (newFilterStatus: IFilterStatus) => {
        if(newFilterStatus === filterStatus) return
        setFilterStatus(newFilterStatus);
        setCurrentSearch(ISearchType.STATUS);
        setIsFilter(false);
        setIsSearchFilter(false);
        window.localStorage.setItem("filterStatus", newFilterStatus)
    }

    const handleToastClose = () => {
        setShowToast({
            open: false,
            message: showToast.message,
            type: showToast.type,
        });
    };

    const handleCopyShareLink = async (shipment: IShipment) => {
        setIsCopyShareLink(true);
        const shipmentOrError = await createShipmentShareLink(shipment.companyId, shipment.id);
        setIsCopyShareLink(false);
        if (shipmentOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: shipmentOrError.message,
                type: "error"
            });
            return;
        }
        await navigator.clipboard.writeText(shipmentOrError.shareLink);
        setShowToast({
            open: true,
            message: "Shipment link copied",
            type: "success"
        });
    }

    const handlePrioritised = async (shipment: IShipment) => {
        setIsPrioritized(true);
        const setPriority = shipment.priority === ShipmentPriority.DEFAULT ? ShipmentPriority.PRIORITIZED : ShipmentPriority.DEFAULT;
        const shipmentOrError = await changeShipmentPriority(shipment.companyId, shipment.id, setPriority);
        setIsPrioritized(false);
        if (shipmentOrError instanceof HumsaferError) {
            setShowToast({
                open: true,
                message: shipmentOrError.message,
                type: "error"
            });
            return;
        }
        fetchShipment(initialSearchObject);
        setShowToast({
            open: true,
            message: `Shipment priority ${setPriority} set successfully`,
            type: "success"
        });
    }

    const handelViewDetails = (data: IShipment) => {
        window.open(`/shipments/${data.id}`, '_blank');
    }

    const refreshShipment = () => {
        fetchFilteredShipment(initialSearchObject);
    }

    const handleDateRangeChange = (dateRange: IDateRange) => {
        if (dateRange !== IDateRange.LAST_MONTH) {
            setDateRange(dateRange);
            window.localStorage.setItem("dateRange", dateRange)
        } else {
            setShowSubscriptionDialog(true)
        }

    }

    return (
        <>
            <Toast
                message={showToast.message}
                open={showToast.open}
                onClose={handleToastClose}
                type={showToast.type}
            />
            {isFetching && (
                <div className={styles.shipment_details_loader}>
                    <CircularProgress size={25} />
                </div>
            )}

            {!isFetching && shipments && (
                <>
                    <HumsaferDialog
                        title="Subscription Required"
                        description={(
                            <p className={styles.shipment_subscription_message}>
                                Access to this data is available exclusively to our paid subscribers. Upgrade your subscription to view the monthly data.
                            </p>
                        )}
                        buttonText={"Okay"}
                        isOpen={showSubscriptionDialog}
                        closeDialog={() => setShowSubscriptionDialog(false)}
                    />
                    {allowCompanySwitcher && <CompanyInfoWithSwitcher />}
                    <Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_top_bar}>
                            <Grid container spacing={1} className={styles.shipment_top_bar_align}>
                                <Grid item xs={6} md={4}>
                                    <div className={styles.shipment_title_align}>
                                        <span className={styles.shipment_top_bar_text}>
                                            All Shipments
                                        </span>
                                    </div>
                                </Grid>
                                {isMobile && (<Grid item xs={6} md={3}>
                                    <div>
                                        <Button
                                            variant="text"
                                            className={styles.shipment_top_bar_refresh}
                                            onClick={refreshShipment}
                                        >
                                            <RefreshIcon />Refresh
                                        </Button>
                                    </div>
                                </Grid>)}

                                <Grid item xs={12} md={4} >
                                    <div className={styles.shipment_buttons_align}>
                                        <Button className={dateRange === IDateRange.TODAY ? styles.shipment_top_bar_button_active : styles.shipment_top_bar_button} onClick={() => { handleDateRangeChange(IDateRange.TODAY) }} variant="text">Today</Button>
                                        <Button className={dateRange === IDateRange.YESTERDAY ? styles.shipment_top_bar_button_active : styles.shipment_top_bar_button} onClick={() => { handleDateRangeChange(IDateRange.YESTERDAY) }} variant="text">Yesterday</Button>
                                        <Button className={dateRange === IDateRange.LAST_WEEK ? styles.shipment_top_bar_button_active : styles.shipment_top_bar_button} onClick={() => { handleDateRangeChange(IDateRange.LAST_WEEK) }} variant="text">Past 7 days</Button>
                                        {/* <Button className={dateRange === IDateRange.LAST_MONTH ? styles.shipment_top_bar_button_active : styles.shipment_top_bar_button} onClick={() => { handleDateRangeChange(IDateRange.LAST_MONTH) }} variant="text"> Past 1 month</Button> */}
                                    </div>
                                </Grid>
                                {!isMobile && (<Grid item xs={6} md={4}>
                                    <div>
                                        <Button
                                            variant="text"
                                            className={styles.shipment_top_bar_refresh}
                                            onClick={refreshShipment}
                                        >
                                            <RefreshIcon />Refresh
                                        </Button>
                                    </div>
                                </Grid>)}

                            </Grid>

                        </Box>
                    </Grid>

                    <Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_top_bar}>
                            <Grid container spacing={1} >
                                <Grid item xs={11} md={11} >
                                    <div className={styles.shipment_top_bar_search}>
                                        <Button
                                            variant="text"
                                            className={styles.shipment_filter_box_button_ml}
                                            onClick={() => [ setIsSearchFilter(!isSearchFilter), setIsFilter(false)] }
                                            sx={{flexGrow: 1}}
                                            endIcon={<SearchIcon />}
                                        >
                                            Search for Shipment ID, Vehicle number, Driver name, Driver Mobile Number
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item xs={1} md={1}>
                                    <IconButton>
                                        <FilterAltIcon onClick={() =>  [setIsFilter(!isFilter), setIsSearchFilter(false)] } />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    {isSearchFilter && (<Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_top_bar}>
                                <Box sx={{ flexGrow: 1 }} className={styles.shipment_filter_box}>
                                    <Grid container spacing={2} justifyContent="center">

                                        <Grid item xs={12} md={4}>
                                            <span className={styles.shipment_filter_box_title}>Search By</span>
                                            {/* Search Input Type*/}
                                            <TextField
                                                value={filterType}
                                                onChange={handleFilterTypeChange}
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                                size="small"
                                                select
                                                label="Search By"
                                            >
                                                <MenuItem value="shipmentId">Shipment ID</MenuItem>
                                                <MenuItem value="vehicleNumber">Vehicle Number</MenuItem>
                                                <MenuItem value="driverName">Driver Name</MenuItem>
                                                <MenuItem value="driverMobileNumber">Driver Mobile Number</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <span className={styles.shipment_filter_box_title}>Search Value</span>
                                            {/* Search Value Input */}
                                            <TextField
                                                label={filterType ? `Enter ${filterTypeOptions[filterType]}` : "Select a search type first"}
                                                value={filterValue}
                                                onChange={handleFilterValueChange}
                                                fullWidth
                                                variant="outlined"
                                                margin='normal'
                                                size='small'
                                                disabled={!filterType} // Disable the input if no filter type is selected
                                            />
                                        </Grid>

                                        <Grid item xs={12} textAlign="center">
                                            <Button
                                                type='button'
                                                variant="outlined"
                                                onClick={() => [setIsSearchFilter(!isSearchFilter), setShipments(filteredAllShipments)]}
                                                className={styles.shipment_filter_box_button_ml}
                                            >
                                                <Loading isLoading={false} text="Close" />
                                            </Button>
                                            <Button
                                                type='submit'
                                                variant="contained"
                                                className={styles.shipment_filter_box_button_ml}
                                                disabled={!filterType || !filterValue}
                                                onClick={onSearchFilter}
                                            >
                                                <Loading isLoading={false} text="Search" />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                        </Box>
                    </Grid>
                    )}
                    {isFilter && (<Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_top_bar}>
                            <Box sx={{ flexGrow: 1 }} className={styles.shipment_filter_box}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12} md={4}>
                                        <span className={styles.shipment_filter_box_title}>By Location</span>
                                        {isTataCompany ? 
                                            <TextField
                                                label="Plant"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                value={loadPlantCode}
                                                onChange={(e) => setLoadPlantCode(e.target.value)}
                                                select
                                            >
                                                {Object.values(allPlantDetails).map((plant) => (
                                                    <MenuItem key={plant.loadPlantCode} value={plant.loadPlantCode}>
                                                        {`${plant.loadPlantCode} - ${plant.loadPlantName}`}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            :
                                            <TextField
                                                label="From"
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                size="small"
                                                value={fromCity}
                                                onChange={(e) => setFromCity(e.target.value)}
                                                select
                                            >
                                                {Object.values(shipmentStartCity).map((city) => (
                                                    <MenuItem key={city} value={city}>
                                                        {city}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        }
                                        <TextField
                                            label="To"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            value={toCity}
                                            onChange={(e) => setToCity(e.target.value)}
                                            select
                                        >
                                            {Object.values(shipmentDestinationCity).map((city) => (
                                                <MenuItem key={city} value={city}>
                                                    {city}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} md={4}>
                                        <span className={styles.shipment_filter_box_title}>By Transporter</span>
                                        <TextField
                                            value={transporter}
                                            onChange={(e) => setTransporter(e.target.value)}
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            size="small"
                                            select
                                            label="Transporter"
                                        >
                                            {Object.values(shipmentTransporter).map((item) => (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} textAlign="center">
                                        <Button
                                            type='button'
                                            variant="outlined"
                                            onClick={() => [setIsFilter(!isFilter), setShipments(filteredAllShipments)]}
                                            className={styles.shipment_filter_box_button_ml}
                                        >
                                            <Loading isLoading={false} text="Close" />
                                        </Button>
                                        <Button
                                            type='submit'
                                            variant="contained"
                                            className={styles.shipment_filter_box_button_ml}
                                            onClick={onSubmitFilter}
                                        >
                                            <Loading isLoading={false} text="Search" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Grid>
                    )}
                    <Grid container>
                        <Box sx={{ flexGrow: 1 }} className={styles.shipment_count_box}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.ALL ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.ALL)} >
                                        <div className={styles.shipment_count_icon_bg}><ViewInArIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{totalCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                All Shipments</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.APP_SET_UP_INCOMPLETE ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.APP_SET_UP_INCOMPLETE)} >
                                        <div className={styles.shipment_count_icon_bg}><ReportIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{appStatusUnhealthyCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                App setup incomplete</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.IN_TRANSIT ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.IN_TRANSIT)} >
                                        <div className={styles.shipment_count_icon_bg}><LocalShippingIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{inTransitCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                In Transit</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.UNTRACKED ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.UNTRACKED)} >
                                        <div className={styles.shipment_count_icon_bg}><VisibilityOffIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{untrackedShipmentCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                Untracked</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.COMPLETED ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.COMPLETED)} >
                                        <div className={styles.shipment_count_icon_bg}><ModeOfTravelIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{completeCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                Completed</span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                    <div className={filterStatus === IFilterStatus.PRIORITIZED ? styles.shipment_count_card_active : styles.shipment_count_card} onClick={() => handleFilterStatusChange(IFilterStatus.PRIORITIZED)} >
                                        <div className={styles.shipment_count_icon_bg}><BeenhereIcon /></div>
                                        <div>
                                            <div className={styles.shipment_count}>{priorityCount}</div>
                                            <span className={styles.shipment_count_text}>
                                                Prioritized</span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid className={styles.shipment_componentContainer} item lg={12} md={12} xs={12}>
                            <Card className={styles.shipment_tableCard}>
                                <div className={styles.shipment_textHeadingConatiner}>
                                    <div className={styles.shipment_tableTitle} style={{ color: primaryColor }}>Shipments</div>
                                    <div style={{ color: primaryColor }}>
                                        <CSVDownload
                                            filename={`Shipments list - ${selectedCompany?.name
                                                ? selectedCompany.name + ' - '
                                                : (Auth.getInstance().getCompanySettings()?.name ? Auth.getInstance().getCompanySettings()?.name + ' - ' : '')
                                                }${getDisplayDateRange(dateRange)} - ${moment().format("DD-MMM-YYYY HH:mm a")}`}
                                            data={getShipmentsListDataForExport(shipments)}
                                        />
                                    </div>
                                </div>
                                <CardContent className={styles.shipment_cardContent}>
                                    <GenericTable
                                        data={shipments.sort((a,b) => b.creationTime - a.creationTime)}
                                        // defaultSortByField={"startTime"}
                                        headers={[
                                            { header: "" },
                                            { header: "Shipment ID" },
                                            { header: "Truck No" },
                                            { header: "Driver Name" },
                                            { header: "From" },
                                            { header: "To" },
                                            { header: "Last Updated On", sortByField: "lastLiveLocationReceivedTime" },
                                            { header: "App Status" },
                                            { header: "Trip Completion", sortByField: "shipmentCompletionPercentage" },
                                            { header: "Created At" },
                                            { header: "View Shipment" },
                                            { header: "" }
                                        ]}

                                        dataRenderer={(data, column) => {
                                            const shipment = data;
                                            switch (column) {
                                                case 0:
                                                    var style;
                                                    if (shipment.status === ShipmentStatus.COMPLETE || shipment.status === ShipmentStatus.CANCELLED) {
                                                        style = styles.shipment_details_status_not_applicable;
                                                    } else {
                                                        switch (shipment.shipmentTrackingStatus) {
                                                            case ShipmentTrackingStatus.ACTIVELY_TRACKED:
                                                                style = styles.shipment_details_status_tracked
                                                                break;
                                                            case ShipmentTrackingStatus.INTERMITTENTLY_TRACKED:
                                                                style = styles.shipment_details_status_intermittent
                                                                break;
                                                            case ShipmentTrackingStatus.MISSING_TRACKING_INFO:
                                                                style = styles.shipment_details_status_not_tracked
                                                                break;
                                                        }
                                                    }
                                                    return <FiberManualRecordIcon className={style} />;
                                                case 1:
                                                    return shipment.vanityId;
                                                case 2:
                                                    return shipment.vehicleNumber;
                                                case 3:
                                                    return shipment.driverName;
                                                case 4:
                                                    return shipment.shipmentStartCity;
                                                case 5:
                                                    return shipment.shipmentDestinationCity;
                                                case 6:
                                                    return formatTimestamp(shipment.lastLiveLocationReceivedTime);
                                                case 7:
                                                    switch (shipment.appHealth) {
                                                        case AppHealth.HEALTHY:
                                                            return (<div>
                                                                <Tooltip children={<CheckCircleIcon className={styles.shipment_details_app_status_healthy} />} title={"Healthy"} />
                                                            </div>);
                                                        case AppHealth.PERMISSIONS_PENDING:
                                                            return (<div>
                                                                <Tooltip children={<AppBlockingIcon className={styles.shipment_details_app_status_unhealthy} />} title={"Permissions pending"} />
                                                            </div>);

                                                        case AppHealth.APP_NOT_INSTALLED:
                                                            return (<div>
                                                                <Tooltip children={<MobileOffIcon className={styles.shipment_details_app_status_unhealthy} />} title={"App not installed"} />
                                                            </div>);
                                                        case AppHealth.APP_UPDATE_REQUIRED:
                                                            return (<div>
                                                                <Tooltip children={<UpgradeIcon className={styles.shipment_details_app_status_unhealthy} />} title={"App update required"} />
                                                            </div>);

                                                    }

                                                    return (<>{shipment.vanityId ? <CheckCircleIcon className={styles.shipment_details_app_status_exist} /> : <CancelSharpIcon className={styles.shipment_details_app_status_not_exist} />}</>)
                                                case 8:
                                                    return (<div>
                                                        <span className={shipment.shipmentCompletionPercentage === 100 ? styles.shipment_details_progress_bar_100 : styles.shipment_details_progress_bar}>
                                                            {shipment.shipmentCompletionPercentage.toFixed(0)}%
                                                        </span>
                                                        <ProgressBar value={shipment.shipmentCompletionPercentage} />
                                                    </div>);
                                                case 9:
                                                    return formatTimestamp(shipment.creationTime)
                                                case 10:
                                                    return (
                                                        <Button
                                                            type='button'
                                                            variant="outlined"
                                                            onClick={() => handelViewDetails(shipment)}
                                                        >
                                                            View
                                                        </Button>
                                                    );
                                                case 11:
                                                    return (
                                                        <ActionMenu
                                                            shipment={shipment}
                                                            handlePrioritised={handlePrioritised}
                                                            handleCopyShareLink={handleCopyShareLink}
                                                            isPrioritized={isPrioritized}
                                                            isCopyShareLink={isCopyShareLink}

                                                        />
                                                    );
                                            }
                                        }}
                                        pagination
                                        filteredRecords={filteredRecords}
                                        onPageChange={handlePageChange}
                                        onRowsPerPageChange={handleRowsChange}
                                        currentPage={currentPage}                                    
                                        totalPages={totalPages}
                                        pageSize={pageSize}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
})

export default ShipmentList;
